<template>
    <div class="sup_content order-list">
        <div class="sup_page_heading">
            <h2>Order Management</h2>
        </div>
        <div class="sup_inner_content">
            <!--- Show Order Table Start --->
            <div class="p-d-flex p-jc-between table-border-bottom">
                <!-- <div class="sup_list_action">
                </div> -->
                <div class="website_orderlist_wrapper">
                    <form @submit.prevent="searchData()">
                    <div class="p-d-flex p-align-left form_colm_row">
                        <div class="form_colm_left">
                            <div class="sup_header_field"><!-- sup_header_field_blank -->
                            <label for="select_date">Date :</label>
                            <Calendar v-model="orderSearch.dateRange" placeholder="DD.MM.YY" id="select_date" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button" inputClass="p-inputtext" :manualInput="false" :showIcon="true" plceholder="abc" />
                        </div>
                        <div class="sup_header_field">
                            <label for="u_role">Customer :</label>
                            <Dropdown v-model="orderSearch.customerUuid" :options="allCustomerData.data" id="u_customer_name" optionLabel="name" optionValue="uuid" placeholder="Select Customer" />
                        </div><div class="sup_header_field">
                            <label for="u_sitename">Website:</label>
                            <Dropdown v-model="orderSearch.websiteId" :options="allWebsiteData.data" id="u_sitename" optionLabel="siteName" optionValue="uuid" placeholder="Select Website" />
                        </div>
                        <div class="sup_header_field">
                            <label for="u_order_status">Order Status :</label>
                            <Dropdown v-model="orderStatusData" :options="orderStatus" id="u_order_status" optionLabel="name" optionValue="code" placeholder="Select Status" />
                        </div>
                        <div class="sup_header_field">
                            <label for="u_order_type">Order Type:</label>
                            <Dropdown v-model="OrderTypeData" :options="OrderType" id="u_order_type" optionLabel="name" optionValue="code" placeholder="Select Invoice Type" />
                        </div>
                        </div>
                        <div class="form_colm_right">
                            <div class="sup_header_field">
                                <Button type="submit" label="Search" class="p-button-success" />
                            </div>
                            <div class="sup_header_field">
                                <Button label="Clear" class="p-button-secondary" @click="reloadPage()" />
                            </div>
                        </div>
                        
                        
                    </div>
                </form>
                    <div class="bottom_order_list">
                        <div class="sup_header_field_blank">
                            <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType"></SplitButton>
                        </div>
                    </div>
                </div>
                
               
            </div>
            <DataTable class="p-datatable order-data-table" :paginator="true" :value="allInvoiceData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allInvoiceData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading orders data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allInvoiceData.search" placeholder="Search Order" @keyup.enter="fetchAllInvoice('search')"/>
                            </span>
                        </div>
                    </div>
                </template>
                
                <template #empty>
                    No order found...
                </template>
                <Column field="chargedAmount" header="Amount" :sortable="true">
                    <template #body="slotProps">
                        <strong>{{'$' +slotProps.data.chargedAmount}}</strong>
                    </template>
                </Column>

                <Column field="orderStatus" header="Status" :sortable="true" headerStyle="8rem">
                    <template #body="slotProps">
                        <span :class="['order_status ',slotProps.data.orderStatus === 'failed' ? 'order_status_danger' : 'order_status_success']"><i :class="['pi ', slotProps.data.orderStatus === 'failed' ? 'pi-times' : 'pi-check']"></i>{{capitalizeCase(slotProps.data.orderStatus)}}</span>
                    </template>
                </Column>

                <Column field="orderType" header="Invoice Type." :sortable="true"></Column>


                <Column field="website" header="Website" sortable filterField="Website" sortField="Website.siteName">
                    <template #body="slotProps">
                        <div class="site_nameurl_list">
                            <span class="sup_site_info">{{slotProps.data.Website.siteName}}</span> <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.Website.siteUrl}}</a>
                        </div>
                        
                    </template>
                </Column>

                <Column field="state" header="Customer" sortable filterField="User" sortField="User.email">
                    <template #body="slotProps">
                    {{slotProps.data.User.email}}
                    </template>
                </Column>

                <Column field="chargedDate" header="Invoice Date" :sortable="true">
                    <template #body="slotProps">
                    {{usFormatDate(slotProps.data.chargedDate)}}
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-eye" class="p-button-sm p-button-rounded p-button-dark p-mr-1" @click="viewInvoice(slotProps.data, 'viewInvoice')" v-tooltip="'View Invoice'" />
                        <Button v-if="slotProps.data.orderStatus !== 'failed'" type="button" icon="pi pi-send" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="sendReceipt(slotProps.data, 'sendInvoice')" v-tooltip="'Send Reciept'" />
                        <Button v-if="slotProps.data.orderStatus !== 'failed'" type="button" icon="pi pi-download" class="p-button-sm p-button-rounded p-button-secondary p-mr-1" @click="downloadInvoice(slotProps.data, 'downloadInvoice')" v-tooltip.left="'Download Reciept'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Order Table End --->
        </div>
        <!--- View Invoice Modal Start --->
        <Dialog header="Invoice Details" v-model:visible="viewInvoiceModal.state" class="sub_customer_view" :modal="true" :style="{width: '800px'}">
            <div class="sup_order_details">
                <div class="sup_order_row">
                    <span class="sup_order_type p-d-flex p-align-center p-mb-2"><i class="pi pi-credit-card p-mr-2"></i> Payment</span>
                    <div class="sup_order_amount p-d-flex p-align-center">
                        <h3>${{viewInvoiceModal.data.chargedAmount}}<span>USD</span></h3>
                        <span :class="['order_status ', viewInvoiceModal.data.orderStatus === 'failed' ? 'order_status_danger' : 'order_status_success']"><i :class="['pi ', viewInvoiceModal.data.orderStatus === 'failed' ? 'pi-times' : 'pi-check']"></i>{{capitalizeCase(viewInvoiceModal.data.orderStatus)}}</span>
                    </div>
                </div>
                <div class="sup_order_row p-mt-4">
                    <div class="sup_box_wrap sup_box_wrap_4 p-d-flex">
                        <div class="sup_box sup_box_border_right">
                            <span class="sup_box_label">Date</span>
                            <span class="sup_box_value">{{usFormatDate(viewInvoiceModal.data.chargedDate) + " " + viewInvoiceModal.data.chargedDate.split("T")[1].split(".")[0]}}</span>
                        </div>
                        <div class="sup_box sup_box_border_right">
                            <span class="sup_box_label">Order No.</span>
                            <span class="sup_box_value">{{viewInvoiceModal.data.invoiceNumber}}</span>
                        </div>
                        <div class="sup_box sup_box_border_right">
                            <span class="sup_box_label">Customer</span>
                            <span class="sup_box_value"><a @click="customerRoute(viewInvoiceModal.data.User.uuid)" target="_blank">{{viewInvoiceModal.data.User.name}}<i class="pi pi-external-link"></i></a></span>
                        </div>
                        <div class="sup_box sup_box_border_right">
                            <span class="sup_box_label">Website</span>
                            <span class="sup_box_value p-d-flex"><a :href="'https://' + [viewInvoiceModal.data.Website.siteUrl]" target="_blank">{{viewInvoiceModal.data.Website.siteName}}<i class="pi pi-external-link"></i></a></span>
                        </div>
                    </div>
                </div>
                <div class="sup_order_row p-mt-4">
                    <h3 class="sup_order_row_title">Transaction Details</h3>
                    <ul>
                        <li>
                            <span class="sup_order_list_label">Period:</span>
                            <span class="sup_order_list_value">{{usFormatDate(viewInvoiceModal.data.billingPeriod.split(" ")[0]) + " to " + usFormatDate(viewInvoiceModal.data.billingPeriod.split(" ")[1])}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Type:</span>
                            <span class="sup_order_list_value">{{textSlipt(viewInvoiceModal.data.orderType)}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Hits:</span>
                            <span class="sup_order_list_value"><strong>{{kFormating(viewInvoiceModal.data.chargedHits)}}</strong>({{numberFormating(viewInvoiceModal.data.chargedHits)}})</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Tire:</span>
                            <span class="sup_order_list_value">{{capitalizeCase(viewInvoiceModal.data.chargedPackage)}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Amount:</span>
                            <span class="sup_order_list_value">${{viewInvoiceModal.data.chargedAmount}}</span>
                        </li>
                    </ul>
                </div>
                <div class="sup_order_row p-mt-4">
                    <h3 class="sup_order_row_title">Transaction Insides<span class="title-badge">Stripe</span></h3>
                    <!-- if payment Successfull -->
                    <ul v-if="viewInvoiceModal.data.Stripetransaction.paid">
                        <li>
                            <span class="sup_order_list_label">Customer Id:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.stripeCustomerId}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Payment Id:</span>
                            <span class="sup_order_list_value"><a :href="viewInvoiceModal.data.Stripetransaction.receiptUrl" target="_blank">{{viewInvoiceModal.data.Stripetransaction.stripePaymentId}}<i class="pi pi-external-link"></i></a></span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Receipt Email:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.receiptEmail}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Method:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.paymentMethod}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Country:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.cardCountry}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Card Number:</span>
                            <span class="sup_order_list_value"><img class="sup_box_cardimg" src="../../../../assets/images/visa.jpg" :alt="viewInvoiceModal.data.Stripetransaction.cardBrand">{{".... "+viewInvoiceModal.data.Stripetransaction.cardLast4}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Expires:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.cardExpMonth + " / " + viewInvoiceModal.data.Stripetransaction.cardExpYear}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Type:</span>
                            <!-- <span class="sup_order_list_value">{{capitalizeCase(viewInvoiceModal.data.Stripetransaction.cardBrand) + " " + capitalizeCase(viewInvoiceModal.data.Stripetransaction.cardFunding)}}</span> -->
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.cardFunding}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Description:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.description}}</span>
                        </li>
                    </ul>
                    <!-- if payment Error -->
                    <ul v-else>
                        <li>
                            <span class="sup_order_list_label">Customer Id:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.stripeCustomerId}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Payment Id:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.stripePaymentId}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Error Type:</span>
                            <span class="sup_order_list_value text-danger">{{viewInvoiceModal.data.Stripetransaction.errorType}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Error Code:</span>
                            <span class="sup_order_list_value text-danger">{{viewInvoiceModal.data.Stripetransaction.errorCode}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Decline Code:</span>
                            <span class="sup_order_list_value text-danger">{{viewInvoiceModal.data.Stripetransaction.errorDeclineCode}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Error Message:</span>
                            <span class="sup_order_list_value">{{viewInvoiceModal.data.Stripetransaction.errorMessage}}</span>
                        </li>
                        <li>
                            <span class="sup_order_list_label">Error Doc Url:</span>
                            <span class="sup_order_list_value"><a :href="viewInvoiceModal.data.Stripetransaction.errorDocUrl" target="_blank">{{viewInvoiceModal.data.Stripetransaction.errorDocUrl}}<i class="pi pi-external-link"></i></a></span>
                        </li>
                    </ul>
                </div>
            </div>
            <template #footer>
                <Button v-if="viewInvoiceModal.data.Stripetransaction.paid" label="Download Invoice" icon="pi pi-download" class="p-button-secondary p-button-sm" @click="downloadInvoice(viewInvoiceModal.data, 'downloadInvoice')" />
                <Button label="Cancel" icon="pi pi-times" class="p-button-secondary p-button-sm p-button-text" @click="viewInvoiceModal.state = false" />
            </template>
        </Dialog>
        <!--- View Invoice Modal End --->
        <!-- Toast to show notification of events start -->
        <Toast position="top-right" />
        <!-- Toast to show notification of events end -->
    </div>
</template>

<script>
import { allInvoice, invoiceById, invoiceDownload, invoiceListExport, sendInvoiceReceipt, allCustomer,allWebsite, searchByAll } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import router from "../../../../router";
import {formatDate, capitalizeCase, usFormatDate, numberFormating, kFormating} from "../../../../helper/utility/utility";
//import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';
import { useRoute } from "vue-router";
const FileSaver = require('file-saver');
import { useToast } from "primevue/usetoast";
import { ref } from 'vue';

export default {
    name: 'OrderManagement',
    setup(){
        //Vuex store data call fucntion
        const store = useStore();

        //Route call
        const route = useRoute();

        //Toast call
        const toast = useToast();

        function reloadPage() {
            window.location.reload();
        }

        const orderStatus = ref([
            {name: 'Paid', code:'paid'},
            {name: 'Failed', code:'failed'}
        ])

        const orderStatusData = ref()

        const OrderType = ref([
            {name: 'Monthly Charges', code:'monthly-charges'},
            {name: 'Script-Installation', code:'script-installation'},
            {name: 'custom-package-price', code:'custom-package-price'},
            {name: 'Language Translation', code:'language-translation'},
            {name: 'Additional Services', code:'additional-services'},
            {name: 'Delete Charges', code:'delete-charges'}
        ])

        const OrderTypeData = ref()

        //Invoice initial data state
        const allInvoiceData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            search: null,
            wuuId : null
        });

        //View invoice modal state
        const viewInvoiceModal = reactive({
            state: false,
            data: false
        });

        const orderSearch = reactive({
            customerUuid : null,
            websiteId : null,
            dateRange :null,
            orderType : null,
            orderStatus : null
        });

        const allCustomerData = reactive({
            data: '',
            status: ''
        });

        const allWebsiteData = reactive({
            data: '',
            status: ''
        });

        //Export type object and method inside
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportPDF('pdf');
                }
            }
        ]);

        /**
         * On component get mounted require functions call
         */
        onBeforeMount( async() => {
            allInvoiceData.wuuId = route.params.id;
            fetchAllInvoice();
            fetchCustomerList();
            fetchWebsiteList();
        });

         /**
         * Fetching all Invoice list from DB
         * intially fetching active Invoice but this fucntion user can get deactive Invoice and filter Invoice by date and name
         * @param {load} from where function calling
         */
        const fetchAllInvoice = async() =>{
            //API call start
            try{
                allInvoiceData.loading = !allInvoiceData.loading;
                const response = await axios.get( allInvoice, {
                    params: {
                        wuuId: allInvoiceData.wuuId,
                        search: allInvoiceData.search
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                    allInvoiceData.data = response.data.data.invoices;
                    allInvoiceData.total = response.data.data.total;
                    allInvoiceData.loading = !allInvoiceData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        /**
         * This fucntion is for viewing invoice
         * @param {slotData} slected item data
         */
        const viewInvoice = async(slotData) => {
            const response = await axios.get( invoiceById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                viewInvoiceModal.state = true;
                viewInvoiceModal.data = response.data.data;
            }
        }

        /**
         * This fucntion is for Downloading invoice
         * @param {slotData} slected item data
         */
        const downloadInvoice = async(slotData) =>{
            const response = await axios.get( invoiceDownload, {
                responseType: 'arraybuffer',
                params: {
                    uuid: slotData.uuid
                },headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if(response.status === 200) {
                const dirtyFileName = response.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                var fileName = dirtyFileName.match(regex)[3];
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver(blob, fileName);
            }
        }

        /**
         * This fucntion is for Export invoice list
         * @param {load} in which format export will happen
         */
        const exportXL = async(load) =>{
            if (load === 'excel') {
                const filterDt = orderSearch.dateRange === null? null : formatDate(orderSearch.dateRange[0]) + ' ' + formatDate(orderSearch.dateRange[1]);
                const response = await axios.get( invoiceListExport, {
                    responseType: 'arraybuffer',
                    params: {
                        search: allInvoiceData.search,
                        // status: allInvoiceData.activityStatus,
                        exe: load,
                        website : orderSearch.websiteId,
                        customer : orderSearch.customerUuid,
                        fiterDate : filterDt,
                        orderType: OrderTypeData.value,
                        orderStatus: orderStatusData.value
                    },headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    },
                });

                if(response.status === 200) {
                    const dirtyFileName = response.headers['content-disposition'];
                    const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                    var fileName = dirtyFileName.match(regex)[3];
                    var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    FileSaver(blob, fileName);
                }
            }
        }

        const exportPDF = async(load) => {
            if (load === 'pdf') {
                const filterDt = orderSearch.dateRange === null? null : formatDate(orderSearch.dateRange[0]) + ' ' + formatDate(orderSearch.dateRange[1]);
                const response = await axios.get( invoiceListExport, {
                    responseType: 'arraybuffer',
                    params: {
                        search: allInvoiceData.search,
                        // status: allInvoiceData.activityStatus,
                        exe: load,
                        website : orderSearch.websiteId,
                        customer : orderSearch.customerUuid,
                        fiterDate : filterDt,
                        orderType: OrderTypeData.value,
                        orderStatus: orderStatusData.value
                    },headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    },
                });

                if(response.status === 200) {
                const dirtyFileName = response.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                var fileName = dirtyFileName.match(regex)[3];
                var blob = new Blob([response.data], { type: 'application/pdf' });
                FileSaver(blob, fileName);
            }
            }
        }

        /**
         * This fucntion is for order specific customer list on customer page
         */
        const customerRoute = (userID) => {
            router.push({name: 'SuperAdminCustomerList', params:{id: userID }});
        }

        /**
         * This function for Send Invoice to user from admin
         */
        const sendReceipt = async(slotData) => {
            const response = await axios.get( sendInvoiceReceipt, {
                params: {
                    uuid: slotData.uuid
                },headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });
            if (response.status === 200) {
                toast.add({severity:'success', summary: 'Receipt Notification', detail: response.data.data, life: 5000});
            }
            console.log(response);
        }

        //Customer list for new customer search dropdown
        const fetchCustomerList = async() => {
            const response = await axios.get( allCustomer, {
                params: {
                    status: 'active',
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                allCustomerData.data = response.data.data.users;
            }
        }

        //Website list for Website search dropdown
        const fetchWebsiteList = async() => {
                const response = await axios.get( allWebsite, {
                    params: {
                        status: 'active',
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allWebsiteData.data = response.data.data.websites;
        }

        /**
         * This function for Search Invoice from header
         */
        const searchData = async() => {
        
        try {
            const filterDt = orderSearch.dateRange === null? null : formatDate(orderSearch.dateRange[0]) + ' ' + formatDate(orderSearch.dateRange[1])
            const response = await axios.get( searchByAll, {
            params: {
                website : orderSearch.websiteId,
                customer : orderSearch.customerUuid,
                fiterDate : filterDt,
                orderType: OrderTypeData.value,
                orderStatus: orderStatusData.value
            }, 
            headers: {
                apiKey: apiKey,
                token: store.getters.adminAuthToken
            },
            });

            if (response.data.status === 200) {
                allInvoiceData.data = response.data.data.invoices;
                allInvoiceData.total = response.data.data.total;
            } 
        } catch (err) {
            console.log('error', err);
        }
        }

        //Returing variables to template
        return{
            allInvoiceData,
            viewInvoiceModal,
            exportType,
            viewInvoice,
            downloadInvoice,
            fetchAllInvoice,
            customerRoute,
            capitalizeCase,
            usFormatDate,
            numberFormating,
            kFormating,
            sendReceipt,
            fetchCustomerList,
            orderSearch,
            allCustomerData,
            fetchWebsiteList,
            allWebsiteData,
            searchData,
            reloadPage,
            orderStatusData,
            orderStatus,
            OrderTypeData,
            OrderType
        }
    },
    methods: {
        /**
         * Text spliting
         */
        textSlipt(value){
            let capitalize = value.charAt(0).toUpperCase() + value.slice(1);
            let splitHypen = capitalize.split('-');
            return splitHypen[0] + ' ' + splitHypen[1];
        }
    }
}
</script>
<style lang="scss" scoped>
.sup_header_field{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}
.sup_header_field_blank{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 10px;
}
.sup_header_field label{
    font-weight: 600;
    color: #000;
}
.p-dropdown .p-dropdown-label {
    font-size: 12px !important;
}
.p-jc-between {
    justify-content: space-between;
    
}
.table-border-bottom{
border-bottom: 1px solid #d1d1d1;
padding: 0 0 20px 0;
margin: 0 0 10px 0;
}
span.sup_search_boxx {
    margin-left: 270%;
    margin-top: 22px
}
.p-button.p-button-success {
    background: #0ba343;
    border-color: #0ba343;
    margin-top:20px
}
.p-button.p-button-secondary.p-component {
    background: #06c8aa;
    border-color: #06c8aa;
    margin-top:20px;
}
.order-data-table .p-datatable-header input,
.order-data-table .p-datatable-header i{
       background-color: #4b5850;
       display: none;
}




@import "../../../../assets/style/component/superAdmin/pages/order/OrderList";
</style>